<template>
  <div class="page-dashboard">
    <app-loading v-show="loadingData" />
    <v-container v-if="!loadingData">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $vuetify.lang.t('$vuetify.plan.blessed') }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list-item
              v-clipboard:copy="user.beneficiaryLink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-bind="attrs"
              href="#"
              v-on="on"
            >
              <v-list-item-action>
                <v-icon color="indigo">
                  mdi-link
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  v-text="user.referralLink"
                />
                <v-list-item-subtitle
                  v-text="
                          $vuetify.lang.t('$vuetify.referralLink')
                        "
                />
              </v-list-item-content>
            </v-list-item>
            <beneficiates-info :user-data="user"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="user.isPartner">
          <v-expansion-panel-header>
            {{ $vuetify.lang.t('$vuetify.menu.partners') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <partner />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>
  </div>
</template>

<script>
import Material from 'vuetify/es5/util/colors'
import { mapActions, mapGetters, mapState } from 'vuex'
import Partner from '../../partners/partner/Partners'
import BeneficiatesInfo from './BeneficiatesInfo'
export default {
  name: 'BeneficiaryDashboard',
  components: { BeneficiatesInfo, Partner },
  data () {
    return {
      panel: [0, 1],
      tab: null,
      timer: '',
      loadingData: false,
      color: Material,
      statistic: [],
      statisticDeny: [],
      localSalesByPayments: [],
      localSalesByCategories: [],
      tabName: [
        {
          name: this.$vuetify.lang.t('$vuetify.dashboard.access'),
          icon: 'mdi-key',
          access: 'manager_access'
        },
        {
          name: this.$vuetify.lang.t('$vuetify.dashboard.info'),
          icon: 'mdi-info',
          access: 'manager_access'
        }
      ],
      trending: [
        {
          subheading: 'Email',
          headline: '15+',
          caption: 'email opens',
          percent: 15,
          icon: {
            label: 'email',
            color: 'info'
          },
          linear: {
            value: 15,
            color: 'info'
          }
        },
        {
          subheading: 'Tasks',
          headline: '90%',
          caption: 'tasks completed.',
          percent: 90,
          icon: {
            label: 'list',
            color: 'primary'
          },
          linear: {
            value: 90,
            color: 'success'
          }
        },
        {
          subheading: 'Issues',
          headline: '100%',
          caption: 'issues fixed.',
          percent: 100,
          icon: {
            label: 'bug_report',
            color: 'primary'
          },
          linear: {
            value: 100,
            color: 'error'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('sale', ['salesByLimit', 'salesStatics']),
    ...mapGetters('auth', ['user'])
  },
  async created () {
    await this.getUserData()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('auth', ['getUserData']),
    onCopy: function (e) {
      this.$Toast.fire({
        icon: 'success',
        title: this.$vuetify.lang.t(
          '$vuetify.partners.confirm_copied'
        ) + e.text
      })
    },
    onError: function (e) {
      this.$Toast.fire({
        icon: 'error',
        title: this.$vuetify.lang.t(
          '$vuetify.partners.error_copied'
        )
      })
    }
  }
}
</script>
