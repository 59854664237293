<template>
  <v-data-table
    :headers="columnHeaders"
    :items="userData.blessed"
  />
</template>

<script>
export default {
  name: 'BeneficiatesInfo',
  props: {
    userData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    columnHeaders () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          align: 'left',
          value: 'firstName'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.company'),
          value: 'company.name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.phone'),
          value: 'company.phone'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'company.country'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
